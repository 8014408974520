<template>
  <b-card no-body>
    <b-card-header class="cursor-pointer" v-b-toggle.search v-if="showHeader">
      <b-row class="w-100">
        <b-col>
          {{ $t("general.search") }}
        </b-col>
        <b-col>
          <feather-icon
            v-if="!visible"
            icon="ChevronDownIcon"
            size="16"
            v-b-tooltip.hover
            class="float-right"
          />
          <feather-icon
            v-if="visible"
            icon="ChevronUpIcon"
            size="16"
            v-b-tooltip.hover
            class="float-right"
          />
        </b-col>
      </b-row>
    </b-card-header>
    <b-collapse id="search" v-model="visible" class="m-2">
      <slot></slot>
      <div class="float-right w-100">
        <b-row v-if="showSearch" align-h="end">
          <b-col md="8" lg="4">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                :placeholder="$t('general.search')"
                v-model="searchText"
              />

              <b-input-group-prepend>
                <b-button
                  variant="primary"
                  @click="search"
                  v-shortkey="['ctrl', 'enter']"
                  @shortkey="search"
                  class="mr-2"
                >
                  {{ $t("general.search") }}
                </b-button>
                <b-button
                  variant="outline-secondary"
                  @click="reset"
                  v-shortkey="['ctrl', 'esc']"
                  @shortkey="reset"
                >
                  {{ $t("general.reset") }}
                </b-button>
              </b-input-group-prepend>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col class="text-right">
            <b-button
              variant="primary"
              @click="search"
              v-shortkey="['ctrl', 'enter']"
              @shortkey="search"
              class="mr-1"
            >
              {{ $t("general.search") }}
            </b-button>
            <b-button
              variant="outline-secondary"
              @click="reset"
              v-shortkey="['ctrl', 'esc']"
              @shortkey="reset"
            >
              {{ $t("general.reset") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardHeader,
  BCol,
  BCollapse,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BSpinner,
  VBToggle,
} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BSpinner,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BCollapse,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    variant: {},
    type: {},
    loading: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: this.active,
      searchText: "",
    };
  },
  methods: {
    search() {
      this.$emit("search", this.searchText);
    },
    reset() {
      this.searchText = "";
      this.$emit("reset");
    },
  },
};
</script>

<style scoped>
</style>
